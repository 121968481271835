.configure-voicemail-boxes {
  .config-field {
    border-bottom: 0.0625em solid $grey-100;
  }

  .label {
    color: rgba($grey-800, 0.8);
  }

  .save-button {
    min-width: 12em;
  }

  .time-stamp {
    font-family: $acumin-pro-font-family;
    font-size: $span-font-size;
  }
}
