.general-voicemail-boxes {
  .general-item {
    .icon {
      color: $secondary;
      font-size: 1.1em;
    }

    .title {
      color: $grey-800;
      font-family: $acumin-pro-font-family;
    }

    .content {
      color: rgba($grey-800, 0.8);
    }
  }

  .tooltip-icon {
    color: $grey-500;
  }

  .configure-button {
    border-radius: 0.375em;
    height: 2.75em;
  }

  .general-voicemail-title {
    color: rgba($grey-800, 0.8);
    font-family: $acumin-pro-font-family;
    width: fit-content;
  }

  .edit-icon {
    @include size(1.8em);
  }
}
