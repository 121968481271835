.contact-detail {
  &.active {
    @include max-break-point(tablet) {
      background-color: $grey-200;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  .return-to-dashboard-button {
    background-color: $white;
    height: 2.5em;

    .back-icon {
      @include size(1.35em);
    }

    &:focus,
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  .active-profile-icon {
    color: $grey-600;
  }

  .profile-header {
    background-color: $white;
    border-radius: 0.5em;

    .name {
      color: $grey-800;
    }

    .company {
      color: $grey-700;
    }

    .right-arrow {
      color: $secondary;
      font-size: 1.3em;
    }
  }

  .contact-information-title {
    color: $grey-800;
  }

  .avatar {
    @include size(4em);

    background-color: $grey-200;

    .user-icon {
      color: $grey-500;
    }

    img {
      @include size(3.5em);
    }
  }

  .user-status {
    @include size(1.35em);

    background-color: $grey-500;
    border: 0.1875em solid $white;
    bottom: -0.05em;
    right: -0.2em;

    //Note: class name is added from the response from api
    &.online {
      background-color: $primary;
    }

    &.busy {
      background-color: $danger;
    }

    &.offline {
      background-color: $grey-500;
    }

    &.ringing {
      animation: blinking 0.5s linear infinite;
      background-color: $danger;
    }
  }

  .title-container {
    background-color: $grey-200;
  }
}
