.login-form {
  form {
    .form-control,
    &::placeholder {
      font-size: $span-font-size;
      height: 4.375em;
      line-height: $span-line-height;
    }
  }

  .error-message {
    color: $danger;
    font-size: $span-font-size;
  }

  .input-icon {
    @include translate-Y(-50%);

    color: $grey-500;
    cursor: pointer;
    font-size: $h5-font-size;
    right: 3%;
    top: 50%;

    &.active {
      color: $grey-800;
    }

    &.error {
      top: 35%;
    }
  }

  .keep-me-signed-in {
    color: $grey-700;
    cursor: pointer;
    font-size: $p-font-size;
    line-height: $h5-line-height;
  }

  .sign-in-button {
    &:disabled {
      background-color: $green-200;
      color: $white;
    }
  }

  .error-alert {
    @include translate-X(-50%);

    left: 50%;
    top: 1%;
  }

  .input-icon {
    @include translate-Y(-50%);

    color: $grey-500;
    cursor: pointer;
    font-size: 1.25em;
    right: 3%;
    top: 50%;

    &.active {
      color: $grey-800;
    }
  }
}
