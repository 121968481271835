.contact-item {
  background-color: $white;
  color: $grey-700;
  height: 3em;

  &.active,
  &:hover,
  &:focus,
  &:active {
    @include custom-box-shadow($primary-box-shadow);

    background: $grey-100;
    border: 0.0625em solid $grey-300;
    border-radius: 0.25em;
    box-sizing: border-box;

    color: $primary;
    outline: unset;

    .active-profile-item {
      svg {
        color: $primary;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }

  .current-name {
    &.active {
      margin-bottom: -0.45em;
    }
  }
}

.contact-item-container {
  background-color: $white;

  &:focus-within,
  &:focus-visible {
    box-shadow: 0;
    outline: unset;
  }

  .alphabet-line {
    background-color: $grey-200;
    border: 0.0625em solid $grey-200;
  }

  .title {
    color: $grey-700;
    font-size: $span-font-size;
    line-height: $span-line-height;
  }

  .avatar {
    @include size(2.5em);

    background-color: $grey-200;

    .user-icon {
      color: $grey-500;
    }

    img {
      @include size(2.5em);
    }
  }

  .user-status {
    @include size(1em);

    background-color: $grey-500;
    border: 0.1875em solid $white;
    bottom: -0.05em;
    right: -0.2em;

    //Note: class name is added from the response from api
    &.not-inuse,
    &.online {
      background-color: $primary;
    }

    &.inuse,
    &.busy {
      background-color: $danger;
    }

    &.unavailable,
    &.offline {
      background-color: $grey-500;
    }

    &.ringing {
      animation: blinking 1.5s linear infinite;
      background-color: $danger;
    }
  }

  .active-profile-item {
    color: $grey-700;
    font-size: $font-12;

    svg {
      font-size: 0.654rem;
    }
  }

  .active-profile-item {
    color: $grey-700;
    font-size: $font-12;

    svg {
      font-size: 0.654rem;
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
