.top-bar {
  background-color: $grey-100;

  border-bottom: 0.0625em solid $grey-200;
  height: 4em;
  inset: 0;
  z-index: 9;

  .current-profile {
    color: $grey-600;
    font-size: $span-font-size;
    margin-bottom: -0.82em;
    margin-left: 0.22em;
    z-index: 1;

    @include min-break-point(tablet-landscape) {
      margin-bottom: 0;
    }
  }

  .name {
    @extend .fw-bold;
    color: rgba($color: $grey-800, $alpha: 0.8);

    font-size: $span-font-size;
  }
}
