.menu {
  .menu-item {
    color: $grey-700;
    height: 2.5em;
    text-decoration: unset;

    &.active,
    &:hover,
    &:active {
      @include custom-box-shadow($collapse-box-shadow);

      background-color: $primary;
      border-radius: 0.5em;
      color: $white;
      text-decoration: unset;
    }

    .menu-item-icon {
      @include size(1.25em);
    }
  }
}
