.forward-to-modal {
  width: 90%;

  @include min-break-point(mobile-phone) {
    width: 80%;
  }

  @include min-break-point(tablet) {
    width: 42em;
  }

  @include min-break-point(larger-desktop) {
    width: 46.87em;
  }

  .header-container {
    background-color: $grey-100;

    h5 {
      color: rgba($color: $grey-800, $alpha: 0.8);
    }
  }

  /* stylelint-disable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
  .forward-to-tab {
    border-right: 0.0625em solid $grey-200;
    min-height: 8em;

    .nav-link {
      border-bottom: 0.0625em solid $grey-200;
      border-radius: 0;
      color: $grey-700;
      font-size: 1em;
      height: 2.5em;

      .indicator {
        @extend .d-none;
      }

      &.active {
        background-color: unset;
        border-right: 0.1975em solid $primary;
        color: $primary;

        .indicator {
          @extend .d-block, .position-absolute, .top-0;

          background-color: $primary;
          height: 2.5em;
          right: -0.15em;
          width: 0.1775em;
        }
      }
    }
  }

  /* stylelint-enable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */

  .add-button {
    min-width: 12em;
  }

  .close-icon {
    color: $grey-700;
    cursor: pointer;
    font-size: 1.4em;
  }

  .accordion-button {
    border-bottom: 0.0625em solid $grey-200;
    height: 2.6875em;

    &:not(.collapsed) {
      background-color: unset;
      box-shadow: unset;
    }

    &:focus,
    &:hover {
      box-shadow: unset;
      outline: none;
    }
  }

  .forward-to-collapse {
    &.open {
      height: auto;
    }

    &.closed {
      display: none !important;
    }

    &.closing {
      animation: height-animation 450ms ease forwards;
    }

    &.opening {
      animation: height-animation 450ms ease reverse;
    }

    @keyframes height-animation {
      0% {
        height: 1.5em;
      }

      100% {
        height: auto;
      }
    }
  }
}
