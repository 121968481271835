.not-found {
  h3,
  h4 {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }

  .description {
    color: rgba($color: $grey-800, $alpha: 0.8);
    font-family: $acumin-pro-font-family;
    font-size: $p-font-size;
  }
}
