.session-expired-modal {
  width: 80%;
  z-index: 9999;

  @include min-break-point(mobile-phone) {
    width: 25.875em;
  }

  @include min-break-point(tablet) {
    width: 28.875em;
  }

  @include min-break-point(larger-desktop) {
    width: 35.875em;
  }

  .logout-button {
    background-color: $primary-dark-color;
    border-radius: 0.5em;
    min-width: 100%;

    @include min-break-point(tablet) {
      min-width: 12em;
    }
  }

  h5 {
    color: rgba($color: $grey-800, $alpha: 0.8);
    font-size: $modal-header-font-size;
  }

  p {
    color: rgba($color: $grey-800, $alpha: 0.8);
    font-family: $acumin-pro-font-family;
    font-size: $p-font-size;
  }
}

.logout-overlay {
  border-radius: 0;
  cursor: default;
  inset: 0;
  z-index: 98;

  @supports not (backdrop-filter: blur(4.0625em)) {
    background-color: rgba($white, 0.9);
  }

  @supports (backdrop-filter: blur(4.0625em)) {
    backdrop-filter: blur(4.0625em);
    background: $light-grey-overlay;
  }
}
