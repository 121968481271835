button {
  border-radius: 0.25em;
  font-family: $neutrif-pro-font-family;
  font-size: $h5-font-size;
  height: 3.375em;
  line-height: $h5-line-height;

  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

a {
  cursor: pointer;
  font-size: $p-font-size;
  line-height: $p-line-height;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

input {
  border-radius: 0.25em;
  font-size: $span-font-size;
  height: 3.125em;
  line-height: $span-line-height;
  width: 28.125em;

  &.checked,
  &.radio {
    @include size(0.875em);
  }

  &::placeholder {
    font-size: $span-font-size;
    line-height: $span-line-height;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

input[type="checkbox"],
input[type="radio"] {
  @include size(0.875em);

  &:checked {
    background-color: $primary;
    border-color: $primary;
  }
}

h1 {
  font-family: $acumin-pro-font-family;
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

h2 {
  font-family: $acumin-pro-font-family;
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}

h3 {
  font-family: $acumin-pro-font-family;
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4 {
  font-family: $acumin-pro-font-family;
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5 {
  @extend .fw-normal;

  font-family: $acumin-pro-font-family;
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

p {
  font-family: $neutrif-pro-font-family;
  font-size: $p-font-size;
  line-height: $h5-line-height;
}

label {
  color: $grey-700;
  font-family: $acumin-pro-font-family;
  font-size: $p-font-size;
  line-height: $h5-line-height;
}
