.contact-list {
  background-color: $white;
  border-radius: 0.5em;
  overflow-x: hidden;
  overflow-y: auto;

  .alphabet-line {
    background-color: $grey-200;
    border: 0.0625em solid $grey-200;
  }

  .logout {
    @include size(2.4em, 5.6em);

    border-radius: 0.2em;
    font-size: $span-font-size;
  }

  .no-result {
    color: $grey-700;
    font-size: $p-font-size;
    line-height: $h5-line-height;
  }
}
