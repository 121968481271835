.configure-queue {
  .available-queue-table {
    th {
      &:last-child {
        @extend .pe-4, .text-end;
      }
    }

    td {
      &:last-child {
        @extend .pe-3, .text-end;
      }
    }

    .add-to-home-button {
      border-radius: 0.25em;
      font-size: $span-font-size;
      height: 2.5em;
    }
  }

  .home-queue-table {
    th {
      &:last-child {
        @extend .text-center;
      }
    }
  }

  .save-button {
    &:disabled {
      background-color: $green-300;
    }
  }
}
