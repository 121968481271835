.btn {
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: $white;

  &:active,
  &:focus {
    background-color: $primary-dark-color;
    border-color: $primary-dark-color;
    box-shadow: none;
    color: $white;
    outline: none;
  }

  &:hover {
    background-color: $primary-light-color;
    border-color: $primary-light-color;
    color: $white;
  }

  &:disabled {
    background-color: $green-200;
    border-color: $green-200;
    color: $white;
  }
}

.btn-danger {
  background-color: $red-500;
  border-color: $red-500;
  color: $white;

  &:active,
  &:focus {
    background-color: $red-300;
    border-color: $red-300;
    box-shadow: none;
    color: $white;
    outline: none;
  }

  &:hover {
    background-color: $red-300;
    border-color: $red-300;
    color: $white;
  }

  &:disabled {
    background-color: $red-200;
    border-color: $red-200;
    color: $white;
  }
}

.btn-danger {
  background-color: $red-500;
  border-color: $red-500;
  color: $white;

  &:active,
  &:focus {
    background-color: $red-300;
    border-color: $red-300;
    box-shadow: none;
    outline: none;
  }

  &:hover {
    background-color: $red-300;
    border-color: $red-300;
  }

  &:disabled {
    background-color: $red-100;
    border-color: $red-100;
    color: $red-100;
  }
}

.link {
  color: $primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $primary;
    text-decoration: underline;
  }
}

.btn-light {
  background-color: $white;
  border-color: $white;
  color: $grey-900;
}

.btn-outline-light {
  background-color: unset;
  border: 0.0625em solid $white;
}

.btn-outline-primary {
  background-color: unset;
  border: 0.0625em solid $primary;
  color: $primary;

  &:hover,
  &:focus {
    background-color: unset;
    border: 0.0625em solid $primary;
    color: $primary;
    outline: unset;
  }
}

.btn-outline-secondary {
  background-color: unset;
  border: 0.0625em solid $grey-800;
  color: $grey-800;

  &:hover,
  &:focus {
    background-color: unset;
    border: 0.0625em solid $grey-600;
    color: $grey-600;
  }
}

.btn-outline-secondary {
  background-color: unset;
  border: 0.0625em solid $grey-800;
  color: $grey-800;

  &:hover,
  &:focus {
    background-color: unset;
    border: 0.0625em solid $grey-600;
    color: $grey-600;
  }
}

.btn-outline-danger {
  &:hover,
  &:focus {
    background-color: unset;
    border: 0.0625em solid $danger;
    color: $danger;
  }
}
