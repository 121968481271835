.note-container {
  .note-title {
    color: $grey-800;
    font-family: $acumin-pro-font-family;
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }

  .title-container {
    background-color: $grey-200;
  }

  .note {
    background-color: $white;
    border-radius: 0.5em;
  }

  .note-pad {
    background-color: $grey-100;
    color: $grey-800;
    min-height: 11em;

    &::placeholder {
      background-color: $grey-100;
    }

    &:focus {
      border-color: unset;
      box-shadow: unset;
      outline: unset;
    }
  }

  .action-container {
    border: 0.0625em solid $grey-200;
  }

  .clear-note-button {
    background-color: $white;
    border-radius: 0.5em;
    color: $grey-500;
    cursor: default;
    font-family: $acumin-pro-font-family;
    font-size: $p-font-size;
    height: 2.25em;
    line-height: $h5-line-height;
    min-width: 8.25em;

    /* stylelint-disable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
    .moving-loading-dots {
      .dot {
        background-color: $grey-700;
      }
    }

    /* stylelint-enable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */

    &.clear {
      color: $grey-700;
      cursor: pointer;
    }
  }

  .clear {
    color: $red-500;
  }

  .save-note-button {
    border-radius: 0.5em;
    font-size: $p-font-size;
    height: 2.6em;
    line-height: $h5-line-height;
  }

  .expire-in {
    color: $grey-700;
  }

  .error-placement {
    height: 1.5em;
    pointer-events: none;
  }

  .react-datepicker-wrapper {
    @extend .w-auto;
  }

  .error-alert {
    @include translate-X(-50%);

    left: 50%;
    top: 5%;
  }

  .remove-expiry-time {
    font-size: $span-font-size;
    height: 2.58em;
    line-height: $h5-line-height;
  }

  .error-time {
    color: $red-400;
    font-size: $span-font-size;
  }
}

.date,
.time {
  background-color: $grey-200;
  border-radius: 0.5em;
  color: $grey-700;
  cursor: pointer;
  font-size: $p-font-size;
  height: 2.25em;
  line-height: $h5-line-height;
  min-width: 6em;

  &.range {
    max-width: 23em;
    min-width: 13em;

    @include min-break-point(tablet-landscape) {
      min-width: 10em;
    }
  }

  .icon {
    color: $grey-500;
    font-size: 1.5rem;
  }

  .close {
    color: $grey-500;
    font-size: 1rem;

    &:hover,
    &:focus {
      @extend .text-danger;
    }
  }

  .placeholder-text {
    left: 80%;
    pointer-events: none;

    &.range {
      left: 68%;
    }
  }

  &.disabled,
  &.disabled input {
    background-color: $grey-100;
    cursor: not-allowed;
  }
}
