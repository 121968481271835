/* stylelint-disable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */

.app {
  .redux-toastr {
    @extend .px-2;

    border-color: $red-500;

    .rrt-error,
    .rrt-light {
      @extend .bg-light;
      @extend .py-1;

      border: 0.0625em solid $red-500;
      border-radius: 0.5em;

      .toastr-icon {
        color: $red-500;
      }

      .rrt-left-container {
        border-left: 0.357em solid $red-500;

        .toastr-icon {
          color: $red-500;
          font-size: 1em;
        }
      }

      .rrt-holder {
        color: $red-500;
        font-size: $modal-header-font-size;
        left: 0.3em;
        top: 2.54em;
      }

      .toastr-status {
        @extend .d-none;
      }
    }

    .rrt-light {
      .rrt-holder {
        @include translate-Y(50%);
      }
    }

    .rrt-success {
      @extend .bg-light;
      @extend .py-1;

      border: 0.0625em solid $primary;
      border-radius: 0.5em;

      .toastr-icon {
        fill: $red-500;
      }

      .rrt-left-container {
        border-left: 0.357em solid $primary;

        .toastr-icon {
          color: $primary;
          font-size: 1.25em;
        }

        .rrt-holder {
          color: $primary;
          font-size: $modal-header-font-size;
          left: 0.3em;
          top: 2.54em;
        }
      }

      .toastr-status {
        @extend .d-none;
      }
    }

    .toastr {
      .rrt-middle-container {
        .rrt-title {
          @extend .mb-1;
          @extend .fw-bold;

          color: $grey-900;
          font-family: $acumin-pro-font-family;
          font-size: $h5-font-size;
        }

        .rrt-text {
          @extend .fw-normal;

          color: $grey-700;
          font-size: $p-font-size;
        }
      }
    }

    .rrt-holder {
      @extend .h-auto;
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .w-100;
    }

    .close-toastr {
      font-size: 1.25em;
      right: 0.2em;
      top: -1em;
    }
  }

  .error-toaster {
    height: 2.5em;
  }

  .error-icon {
    @include size(1.25em);

    color: $red-500;
  }
}

/* stylelint-enable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
