.call-queue-detail {
  border-radius: 0.5em;

  .description {
    color: $grey-700;
    font-family: $acumin-pro-font-family;
    font-size: $span-font-size;
  }
}

.call-queue-dashboard {
  height: calc(100vh - 5.2em);

  @include min-break-point(tablet) {
    width: calc(100% - 0.6em);
  }
}

.back-icon {
  color: rgba($grey-800, 0.8);
  cursor: pointer;
}

.edit-call-queue {
  height: fit-content;
  min-height: 100%;

  .register-agent-title {
    color: rgba($grey-800, 0.8);
    font-family: $acumin-pro-font-family;
    font-size: $h5-font-size;
  }

  .avatar {
    @include size(2.3em);

    background-color: $grey-200;

    .user-icon {
      color: $grey-500;
    }

    img {
      @include size(2em);
    }
  }

  .user-status {
    @include size(0.9em);

    background-color: $grey-500;
    border: 0.1875em solid $white;
    bottom: -0.05em;
    right: -0.25em;

    //Note: class name is added from the response from api
    &.online {
      background-color: $primary;
    }

    &.busy {
      background-color: $danger;
    }

    &.offline {
      background-color: $grey-500;
    }

    &.ringing {
      animation: blinking 0.5s linear infinite;
      background-color: $danger;
    }
  }

  .register-agent {
    .agent-name {
      color: rgba($color: $grey-800, $alpha: 0.8);
      line-height: $p-line-height;
    }

    .agent-status {
      color: $grey-700;
      font-size: $font-12;

      &.busy {
        color: $red-600;
      }

      &.offline {
        color: $grey-500;
      }

      &.online {
        color: $primary;
      }

      &.ringing {
        color: $danger;
      }
    }
  }

  .no-agent-detail {
    color: $grey-700;
    font-size: $span-font-size;
  }

  .call-queue-title {
    background-color: $grey-200;
    border-radius: 0.5em 0.5em 0 0;
    height: 3.8125em;

    .queue-name {
      color: rgba($grey-800, 0.8);
      font-family: $acumin-pro-font-family;
      font-size: $h5-font-size;
      font-weight: 700;
    }
  }

  .manage-agent-button {
    border-radius: 0.5em;
    font-size: $span-font-size;
    height: 2.0625em;

    &:hover,
    &:focus {
      background-color: unset;
      border: 0.0825em solid $primary;
      color: $primary;
    }
  }
}

.no-call-queue {
  background-color: $grey-300;
  border-radius: 0.25em;

  .title {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }
}
