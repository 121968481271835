.table {
  &.min-table {
    min-width: 46em;
  }

  .header-cell,
  &.cell {
    color: $grey-800;
    font-size: $p-font-size;
  }

  .table-header {
    background-color: $grey-200;
    border-radius: 0.5em 0.5em 0 0;
    height: 2.9em;
  }

  .sort-icon {
    color: $grey-700;
    font-size: 1.125em;
  }

  .table-row {
    @include custom-box-shadow(0 0.0625em 0 $grey-200);

    height: 2.9em;

    &:nth-child(odd) {
      background-color: $grey-100;
    }

    &:nth-child(even) {
      background-color: $white;
    }

    &:last-child {
      @extend .border-0;
    }

    &.add-hover {
      &:hover,
      &:focus,
      &:active {
        background-color: $primary-rgba-color;
      }
    }
  }

  .sort-down-icon {
    color: $grey-700;
    top: 7%;

    &.disabled {
      color: $grey-500;
      pointer-events: none;
    }
  }

  .sort-up-icon {
    color: $grey-700;

    &.disabled {
      color: $grey-500;
      pointer-events: none;
    }
  }

  .table-checkbox {
    @include size(0.995em);

    border: 0.16em solid $grey-700;
    border-radius: 0.2em;
  }
}
