.top-header {
  border-bottom: 0.0625em solid $grey-200;

  .voice-search-bar {
    width: 18.192em;
  }

  .filter-dates,
  .range {
    color: rgba($grey-800, 0.8);
    font-family: $acumin-pro-font-family;
    font-size: $span-font-size;
    height: 3em;
  }

  .range-container {
    @include max-break-point(mobile-phone) {
      min-width: 100%;
    }
  }

  .placeholder-text {
    &.range {
      height: 2em;
      left: 22%;
      transform: translateX(-10%);

      @include min-break-point(mobile-phone) {
        left: 50%;
        transform: translateX(-30%);
      }
    }
  }
}

.cover-image {
  object-fit: cover;
}
