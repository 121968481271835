.agent-management-sidebar {
  background-color: $white;
  margin-right: -50em;
  min-height: 100%;
  right: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  z-index: 99;

  @include min-break-point(tablet) {
    max-width: 50em;
  }

  &.toggle {
    animation: sidebar 0.5s ease-in-out forwards;
    margin-right: 0;
  }

  @keyframes sidebar {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  .agent-management-header {
    border-bottom: 0.0636em solid $grey-200;
    height: 4.4375em;

    .title {
      color: rgba($color: $grey-800, $alpha: 0.8);
      font-family: $neutrif-pro-font-family;
    }

    .close-icon {
      color: $grey-700;
      cursor: pointer;
      font-size: 1.4em;
    }
  }

  .available-agent-title {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }

  .search-icon {
    left: 0.5em;
  }

  .table-header-contain {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }

  table {
    border-collapse: collapse;
    border-radius: 0.5em 0.5em 0 0;

    min-width: 42.5em;
  }

  th,
  td {
    background: unset;
  }

  tbody,
  .empty-table-content {
    border-inline: 0.0625em solid $grey-400;
  }

  .device-icon {
    color: $grey-700;
    font-size: 1.3em;
  }

  .device-name {
    color: $grey-700;
    font-size: $span-font-size;
    line-height: $p-line-height;
  }

  .selected-employee {
    background-color: $grey-100;
    border-inline: 0.0625em solid $grey-400;

    button {
      font-size: $span-font-size;
      height: 2.25em;
    }

    .number-of-selected-employee {
      color: $grey-700;
      font-size: $span-font-size;
    }

    .count-of-employee {
      color: rgba($color: $grey-800, $alpha: 0.8);
      font-size: $p-font-size;
    }
  }

  .agents-content {
    height: calc(100vh - 12em);
  }

  .cancel-button {
    color: $grey-700;
  }

  .agent-delete-button {
    color: $grey-500;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $red-400;
    }
  }

  .submit-container {
    @include custom-box-shadow(0 -0.25em 0.75em rgba($black, 0.1));
  }

  .error-message {
    color: $danger;
    font-size: $span-font-size;
  }

  .save-button {
    &:disabled {
      background-color: rgba($color: $secondary, $alpha: 0.8);
      border: 0;
    }
  }
}
