.note-message {
  pointer-events: stroke;

  .note-icon {
    color: $red-600;
    cursor: pointer;
  }

  .tooltip-note-icon {
    color: $primary-light-color;
    font-size: $p-font-size;
  }
}
