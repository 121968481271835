.custom-spinner {
  &.large {
    @include size(2.5em);
  }

  &.medium {
    @include size(2em);
  }

  &.small {
    @include size(1.1em);
  }
}
