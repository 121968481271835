.search-bar-container {
  .search-bar {
    border-radius: 0.5em;
    height: 2.5em;
    padding-left: 2.1em;

    &::placeholder {
      font-size: $search-bar-placeholder-font-size;
    }
  }

  &.table-search-bar {
    .search-icon {
      @include max-break-point(tablet) {
        left: 0.5em;
      }
    }
  }

  .search-icon {
    @include translate-Y(-50%);

    color: $grey-500;
    font-size: 1.125em;
    left: 1em;
    top: 50%;

    @include min-break-point(mobile-phone) {
      left: 1.3em;
    }

    @include min-break-point(tablet) {
      left: 1.4em;
    }
  }
}
