.login-container {
  background-color: $grey-100;

  .login-header {
    @include max-break-point(mobile-phone) {
      font-size: $h5-font-size;
      line-height: $h5-line-height;
    }
  }

  .login {
    background-color: $white;
    border: 0;
    border-radius: 0.5em;
    width: 100%;
  }
}
