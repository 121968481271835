@keyframes dot-keyframes {
  0% {
    @include scale(1, 1);

    opacity: 0.4;
  }

  50% {
    @include scale(1.2, 1.2);

    opacity: 1;
  }

  100% {
    @include scale(1, 1);

    opacity: 0.4;
  }
}

.moving-loading-dots {
  .dot {
    @include size(0.6em);

    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: $white;
    border-radius: 0.625em;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}
