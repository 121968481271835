.pbx-user-dropdown {
  &.light-background {
    .dropdown-toggle {
      background-color: $grey-100;
    }
  }

  .dropdown-toggle {
    background-color: $grey-200;
    border-radius: 0.375em;
    color: rgba($color: $grey-800, $alpha: 0.8);
    font-family: $acumin-pro-font-family;
    font-size: $span-font-size;

    @include max-break-point(tablet) {
      min-width: 13em;
    }
  }

  .custom-dropdown-menu {
    max-height: 15em;
  }

  .vertical-line {
    height: 2em;
  }
}

.filter-container-skeleton {
  min-width: 10em;
}
