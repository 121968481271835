.external-number {
  .header {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }

  .tooltip-icon {
    color: $grey-700;
  }

  input {
    height: 3em;
  }

  label {
    font-size: $span-font-size;
  }

  .external-button {
    height: 3em;

    &:disabled {
      background-color: $grey-300;
      border-color: $grey-300;
      color: $grey-700;
    }
  }
}
