.overlay {
  background: $primary-overlay;
  border-radius: 0;
  cursor: default;
  inset: 0;
  z-index: 100;
}

.overlay-sidebar {
  background: $primary-overlay;
  border-radius: 0;
  cursor: default;
  inset: 0;
  z-index: 98;
}
