.new-password {
  .description {
    color: $grey-700;
  }

  .recover-alert {
    @include translate-X(-50%);

    left: 50%;
    top: 5%;
  }

  .new-password-button {
    &:disabled {
      background-color: $green-200;
      color: $white;
    }
  }
}
