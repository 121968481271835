.voicemail-skeleton {
  .table-header {
    background: $grey-200;
  }

  .filter-container {
    min-width: 6em;
  }

  .search-skeleton {
    max-width: 12em;
  }
}
