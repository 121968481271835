.confirm-modal {
  width: 90%;
  z-index: 101;

  @include min-break-point(mobile-phone) {
    width: 80%;
  }

  @include min-break-point(tablet) {
    width: 34em;
  }

  @include min-break-point(larger-desktop) {
    width: 40.87em;
  }

  &.delete-registered-agent {
    @include min-break-point(tablet) {
      width: 37em;
    }

    @include min-break-point(larger-desktop) {
      width: 42.87em;
    }
  }

  .heading,
  &.paragraph {
    color: $grey-800;
  }

  .heading {
    font-size: $modal-header-font-size;
  }

  .confirm-button,
  .cancel-button {
    @include max-break-point(mobile-phone) {
      width: 100%;
    }
  }

  .confirm-button {
    min-width: 11em;
  }

  .delete-content {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }

  .single-registered-agent {
    margin-top: -1em;
  }
}
