.information-box-wrapper {
  border: 0.0625em solid $grey-200;
  border-radius: 0.5em;

  .header {
    background-color: $grey-200;
    border-radius: 0.5em 0.5em 0 0;

    .icon-container {
      @include size(2.125em);

      background-color: $primary-dark-color;
    }

    .icon {
      @include size(1.3em);

      filter: brightness(0) invert(1);
    }

    .title-content {
      @include max-break-point(tablet) {
        width: fit-content;
      }
    }
  }
}
