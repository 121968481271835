.call-queue-statistics {
  .statistics-item {
    .statistics-value {
      color: rgba($grey-800, 0.8);
      font-family: $acumin-pro-font-family;
      font-size: $h4-font-size;
    }

    .statistics-name {
      color: $grey-600;
      font-family: $acumin-pro-font-family;
      font-size: $font-12;
      line-height: $p-line-height;
    }
  }

  &.home {
    .statistics-value {
      font-size: $p-font-size;
    }

    .statistics-name {
      font-size: $font-10;
    }
  }
}
