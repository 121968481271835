.language-toggle {
  .language-text {
    color: $grey-700;
    font-family: $neutrif-pro-font-family;
  }

  .language-button {
    @include size(2.6em);

    background-color: $grey-200;
    border: 0;
    border-radius: 0.625em;
    color: $grey-800;
    font-size: $font-12;

    &.active {
      background-color: $primary;
      color: $white;
    }

    &:focus {
      box-shadow: unset;
    }
  }

  .language-icon {
    color: $grey-500;
    font-size: $h4-font-size;
  }
}
