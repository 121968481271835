.cell-phone-voicemail-boxes {
  .cell-phone-table {
    .table-header {
      @include custom-box-shadow(inset 0 0.0625em 0 $grey-300);

      background-color: $grey-100;
    }
  }

  tr {
    td,
    th {
      @extend .text-center;
    }
  }

  .header-cell,
  td {
    color: rgba($grey-800, 0.8);
    font-size: $span-font-size;
  }

  .edit-icon {
    color: $secondary;
    cursor: pointer;
    font-size: 1.1rem;
    min-width: 4.5em;
  }

  .cell-number-search-bar {
    width: 100%;

    @include min-break-point(tablet) {
      width: 18.375em;
    }
  }

  .cell-phone-table-row {
    &:nth-child(even) {
      background-color: $grey-100;
    }

    &:nth-child(odd) {
      background-color: $white;
    }
  }

  .content {
    min-height: 14em;
  }
}
