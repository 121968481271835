.edit-profile-form {
  form {
    .form-control,
    &::placeholder {
      font-size: $span-font-size;
      height: 4.375em;
      line-height: $span-line-height;
    }
  }

  .error-message {
    color: $danger;
    font-size: $span-font-size;
  }

  .label {
    color: $grey-700;
    font-family: $acumin-pro-font-family;
  }

  .edit-button {
    border-radius: 0.375em;
  }
}
