.send-email {
  width: 80%;

  @include min-break-point(tablet) {
    width: 34.875em;
  }

  .heading {
    font-size: $modal-header-font-size;
  }

  .sub-heading {
    color: $grey-800;
  }

  .select-mail {
    border: 0.0125em solid $grey-400;
    border-radius: 0.25em;

    min-height: 7.8em;
    width: 100%;

    @include min-break-point(tablet) {
      width: 14.32em;
    }

    &.active,
    &:hover,
    &:focus {
      border-color: $green-400;
    }

    &:nth-child(1) {
      @include min-break-point(tablet) {
        margin-right: 1em;
      }
    }

    &:nth-child(2) {
      @include min-break-point(tablet) {
        margin-left: 1em;
      }
    }

    .send-mail-heading,
    .email {
      color: $grey-700;
      font-size: $span-font-size;
      line-height: $span-line-height;
    }
  }

  .form-check-input {
    @include size(1.25em);

    cursor: pointer;
  }

  .close-icon {
    color: $grey-500;
    cursor: pointer;
    font-size: 1.5em;
  }

  .back-button {
    color: $grey-500;
    height: 2em;
  }

  .paragraph {
    color: $grey-800;
  }

  .form-check-label {
    cursor: pointer;
  }
}
