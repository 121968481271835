.empty-content {
  background-color: $white;

  .title {
    color: $grey-800;
  }

  .detail {
    color: $grey-700;
  }

  .content-block {
    left: 0.95em;
    top: 0.9em;
    z-index: 2;
  }
}

.empty-content-header {
  background-color: $grey-200;
  height: 2.5em;
}
