.configure-devices {
  .device-list {
    @include custom-box-shadow($collapse-box-shadow);
    border: 0.0625em solid $grey-400;
    border-radius: 0.5em;
    display: none;
    min-width: 16em;
    transform: translateX(-88%);

    &::before {
      @extend .position-absolute;
      border-color: $white transparent transparent transparent;
      border-style: solid;
      border-width: 0.6125em;
      content: "";
      margin-left: -0.3125em;
      right: 4%;
      top: -1.16em;
      transform: rotate(181deg);
    }

    &.active {
      display: block;
    }
  }

  .gear-icon {
    color: $primary;
    cursor: pointer;
    font-size: 1.2em;

    &:hover,
    &:focus {
      color: $primary-dark-color;
    }

    &.active {
      color: $primary-dark-color;
    }
  }

  .device-item {
    cursor: pointer;
    list-style: none;

    .device-toggler {
      @include size(1.2em, 2.2em);

      margin-top: 0.3em;
    }
  }

  .device-item {
    background: $grey-100;
    border-radius: 0.5em;

    label {
      color: rgba($color: $grey-800, $alpha: 0.8);
      font-size: $span-font-size;
    }

    .device-icon {
      color: $grey-700;
    }
  }

  .device-number,
  .form-check-label,
  .device-toggler {
    cursor: pointer;
  }

  .device-title {
    color: rgba($grey-800, 0.8);
    font-family: $acumin-pro-font-family;
  }

  .configure-device-tooltip {
    border-radius: 0.625em;
    bottom: unset;
    cursor: pointer;
    font-size: $span-font-size;
    left: unset;
    min-width: 18em;
    right: 0;
    top: 100%;
    transform: unset;
    z-index: 1;

    &::after {
      border-color: $black transparent transparent transparent;
      bottom: 97%;
      content: "";
      left: unset;
      margin-left: 0;
      margin-right: 0.2em;
      right: 0;
      top: unset;
      transform: rotate(183deg);
    }

    &.active {
      @extend .d-none;
    }
  }
}
