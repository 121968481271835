.fixed-calendar-banner {
  background-color: $grey-100;
  border: 0.0625em solid $grey-300;
  border-radius: 0.5em;

  @include min-break-point(tablet) {
    min-height: 3.81em;
  }

  .fixed-banner-button {
    height: 2.25em;
    min-height: fit-content;
  }

  .banner-text {
    color: rgba($grey-800, 0.8);
  }
}
