.profile-dropdown {
  .profile-button {
    background-color: unset;
    height: 2em;

    &::after {
      content: unset;
    }

    &:focus,
    &:active,
    &:focus-visible {
      border: unset;
      box-shadow: unset;
      outline: unset;
    }

    .dropdown-icon {
      color: $grey-500;
    }
  }

  .profile-dropdown-menu {
    @include custom-box-shadow($collapse-box-shadow);

    min-width: 11em;

    li {
      border-bottom: 0.0625em solid $grey-100;

      &:nth-last-of-type(2) {
        @extend .border-bottom;
      }

      &:last-child {
        @extend .border-0;
        /* stylelint-disable-next-line selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
        svg {
          color: $red-500;
        }
      }
    }

    .dropdown-item {
      border: 0.0625em solid $grey-200;
      color: $grey-700;
      font-family: $acumin-pro-font-family;
      font-size: $p-font-size;
      height: 2.6875em;
      line-height: $h5-font-size;

      &.active,
      &:focus,
      &:active {
        background-color: unset;
        border-radius: 0;
        color: $grey-800;
      }

      &:hover {
        background-color: unset;
        border-radius: 0;
        color: $grey-800;
      }
    }
  }

  .avatar {
    @include size(3em);

    background-color: $grey-200;

    .user-icon {
      color: $grey-500;
      font-size: 1.125em;
    }

    img {
      @include size(2.5em);
    }
  }

  .user-status {
    @include size(1em);

    background-color: $grey-500;
    border: 0.1875em solid $white;
    bottom: -0.05em;
    right: -0.2em;

    //Note: class name is added from the response from api
    &.online,
    &.active {
      background-color: $primary;
    }

    &.busy {
      background-color: $danger;
    }

    &.offline {
      background-color: $grey-500;
    }

    &.ringing {
      animation: blinking 1.5s linear infinite;
      background-color: $danger;
    }
  }

  .logout-icon {
    color: $red-500;
    font-size: 1em;
  }

  .profile-icon {
    color: $grey-500;
    font-size: 1.2em;
  }
}
