.voicemail-table {
  .delete-icon {
    cursor: pointer;
    font-size: 1.25em;
  }

  .email-icon {
    cursor: pointer;
    font-size: 1.25em;
  }

  .email-icon {
    cursor: pointer;
    font-size: 1.25em;
  }

  .listen-icon {
    cursor: pointer;
    font-size: 1.25em;
  }

  .download-icon {
    color: $grey-700;
    cursor: pointer;
    font-size: 1.25em;
  }

  .table-row {
    &:hover,
    &:focus,
    &:active {
      background-color: $primary-rgba-color;
    }
  }

  table {
    td,
    th {
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }
}
