/* stylelint-disable  selector-class-pattern, unit-allowed-list, declaration-no-important */
.fixed-calendar {
  .calendar-text,
  .calendar-header {
    color: rgba($grey-800, 0.8);
    cursor: pointer;
    font-family: $acumin-pro-font-family;
  }

  .calendar-description {
    color: $grey-700;
  }

  .calendar-header {
    font-size: $font-12;
    text-decoration: unset;
  }

  .fc-col-header-cell-cushion {
    @extend .d-flex, .justify-content-center, .align-items-center, .py-3;

    text-decoration: none;
  }

  .fc-timegrid-slot-label-cushion {
    color: $grey-700;
    font-size: $font-12;
  }

  table {
    th {
      background-color: $grey-100;
    }
  }

  .edit-icon-container {
    @include size(2em);
    background-color: $grey-300;

    border: 0.0625em solid $grey-500;
  }

  .edit-icon {
    color: $grey-700;
  }

  .fc {
    overflow-x: auto;
  }

  .fc-view-harness {
    @include max-break-point(tablet-landscape) {
      min-width: 32em;
    }
  }

  .fc-event-title,
  .fc-event-time {
    color: rgba($color: $grey-800, $alpha: 0.8);
    font-size: $font-12;
  }

  .fc-event-container {
    @extend .m-0;
  }

  .fc-highlight {
    background-color: $mint-cream;
  }

  .fc-timegrid-event {
    background-color: $mint-cream;
    border: 0.0625em solid rgba($color: $grey-800, $alpha: 0.4);
    border-radius: 0;
    cursor: default;
    margin: 0 -0.15625em;
  }

  .fc-scrollgrid-liquid {
    min-width: 46em;
  }

  .fc-event-title-container {
    @extend .d-flex, .justify-content-center, .align-items-center, .px-2;
  }

  .fc-event-time {
    @extend .d-none;
  }

  .fc-col-header,
  .fc-timegrid-body {
    @include max-break-point(tablet-landscape) {
      width: 100% !important;
    }
  }
}

/* stylelint-enable selector-class-pattern, unit-allowed-list, declaration-no-important */
