.call-profile-list-item {
  @include custom-box-shadow($card-box-shadow);

  background-color: $grey-100;
  border: 0.125em solid $grey-100;
  border-radius: 0.25em;
  cursor: pointer;
  min-height: 9em;
  min-width: 13.887em; // Added min width to avoid shrink of card
  width: 90%;

  @include min-break-point(tablet) {
    min-width: 12.887em;
    width: 12.887em;
  }

  @include min-break-point(tablet-landscape) {
    min-width: 13.887em;
    width: 13.887em;
  }

  &.active,
  &:hover,
  &:focus {
    border: 0.125em solid $primary-dark-color;
  }

  &.skeleton {
    cursor: default;

    &:hover,
    &:focus {
      border: 0.125em solid $grey-100;
    }
  }

  .icon-container {
    @include size(3em);

    background-color: $primary-dark-color;
  }

  .item-title {
    font-family: $acumin-pro-font-family;
    line-height: $p-line-height;
  }

  .item-description {
    color: $grey-700;
    line-height: $h5-line-height;
  }

  .profile-icon {
    font-size: 1.3rem;
  }
}

.call-profile-list {
  @include min-break-point(tablet) {
    border-right: 0.0625em solid $grey-100;
  }

  .list {
    /* stylelint-disable-next-line unit-allowed-list declaration-no-important */
    max-height: calc(100vh - 19.5em);

    @include min-break-point(tablet) {
      max-height: calc(100% - 3em);
    }

    &.create {
      @include min-break-point(tablet) {
        max-height: calc(100% - 0.1em);
      }
    }
  }

  .profile-content {
    .call-profile-list-item {
      &:first-child {
        @extend .mt-0;
      }
    }
  }

  .create-button {
    border-radius: 0.5em;
    height: 2.8em;
    min-width: 90%;

    @include min-break-point(tablet) {
      min-width: 12.887em;
    }

    @include min-break-point(tablet-landscape) {
      width: 100%;
    }

    .create-icon {
      font-size: 1.5em;
    }
  }

  .create-button-skeleton {
    background-color: $grey-100;
    height: 2.5em;
    min-width: 14em;
    width: 90%;

    @include min-break-point(tablet) {
      max-width: 14em;
      width: unset;
    }
  }

  .active-badge {
    @include size(2.15em, 5.125em);

    background-color: $green-100;
    border-radius: 0.875em;
    color: $primary-dark-color;
    font-size: $font-12;
    right: 0.5em;
    top: 0.5em;
  }
}
