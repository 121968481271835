.sidebar-container {
  background-color: $grey-200;
  max-width: 17em;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 0;

  @include max-break-point(tablet) {
    margin-right: -15em;
    right: 0;
  }

  @include min-break-point(tablet) {
    left: 0;
    margin-left: -15em;
    max-width: 15em;
  }

  @include min-break-point(tablet-landscape) {
    background-color: unset;
    margin-left: 0;
    position: relative;
    width: 100%;
  }

  @include max-break-point(tablet-landscape) {
    background-color: $white;
    z-index: 100;

    &.mobile-toggle {
      animation: sidebar 0.5s ease-in-out forwards;
      margin-left: 0;
      max-width: 15em;
    }
  }

  &.mobile-toggle {
    animation: sidebar 0.5s ease-in-out forwards;

    @include max-break-point(tablet) {
      margin-right: 0;
      max-width: 17em;
    }
    @include min-break-point(tablet) {
      margin-left: 0;
    }
  }

  &.collapsed {
    max-width: 4.5em;
  }

  .close {
    color: $primary;

    @include max-break-point(tablet) {
      color: $white;
      left: -1.5em;
      position: absolute;
      top: 0;
    }
  }

  .sidebar {
    border-right: 0.0625em solid $grey-300;
  }

  .collapse-button {
    @include size(2em);
    @include custom-box-shadow($collapse-box-shadow);

    background-color: $white;
    bottom: 2.8em;
    right: -0.85em;

    .arrow-icon {
      color: $primary;
    }
  }

  @keyframes sidebar {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  .dropdown-icon {
    transform: rotate(270deg);
  }

  .active-profile-title {
    color: $grey-600;
    font-size: $font-10;
    top: -1.7em;
  }

  .active-profile-icon {
    color: $grey-500;
    font-size: 1.45em;
    margin-bottom: 0.3em;
  }

  /* stylelint-disable selector-class-pattern,selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
  .custom-dropdown {
    ul {
      &.sidebar-active-call-profile {
        @extend .pt-0, .px-0, .mb-2;

        .item-list {
          @extend .overflow-auto;

          max-height: 11.6em;

          .dropdown-item {
            font-family: $neutrif-pro-font-family;

            &.active-option {
              color: $grey-800;
            }
          }
        }

        li {
          border-bottom: 0.0625em solid rgba($black, 0.1);
        }

        .item-title {
          @extend .py-3;

          background-color: $grey-100;
          color: rgba($grey-800, 0.8);
          font-family: $neutrif-pro-font-family;

          font-size: $span-font-size;

          &:hover {
            background-color: $grey-100;
            color: rgba($grey-800, 0.8);
          }
        }
      }
    }
  }
  /* stylelint-enable selector-class-pattern,selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
}
