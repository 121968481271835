* {
  box-sizing: border-box;
  font-family: $neutrif-pro-font-family;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}
