.voicemail-boxes {
  .title-container {
    border-bottom: 0.0625em solid $grey-200;

    .title {
      color: $grey-800;
    }
  }

  .search-bar-container {
    .search-icon {
      @include max-break-point(tablet) {
        left: 0.5em;
      }
    }
  }

  .title-content {
    &.information-search {
      @include max-break-point(tablet) {
        width: 100%;
      }
    }
  }
}
