.call-history-table {
  .cell-icon {
    width: 3.4em;
  }

  .table {
    .missed-call {
      background-color: $red-100;
    }
  }
}
