.custom-dropdown {
  .custom-button {
    background-color: unset;
    color: $green-800;
    font-family: $acumin-pro-font-family;
    font-size: $h5-font-size;
    height: 2em;
    line-height: $h5-line-height;

    &:focus,
    &:active,
    &:focus-visible {
      border: unset;
      box-shadow: unset;
      outline: unset;
    }
  }

  .active-profile {
    background-color: $grey-100;
    border-radius: 0.5em;
    color: $grey-700;
    font-size: $p-font-size;

    .dot {
      background-color: $grey-500;
    }

    .moving-loading-dots {
      max-width: 3em;
    }
  }

  .dropdown-toggle {
    &::after {
      content: unset;
    }
  }

  .custom-dropdown-menu {
    @include custom-box-shadow($collapse-box-shadow);

    li {
      border-bottom: 0.0625em solid $grey-100;

      &:last-child {
        @extend .border-0;
      }
    }

    .dropdown-item {
      color: $grey-700;
      font-family: $acumin-pro-font-family;
      font-size: $p-font-size;
      height: 2.6875em;

      &.active,
      &:focus,
      &:active {
        @extend .fw-bold;

        background-color: unset;
        border-radius: 0;
        color: $secondary;
      }

      &:hover {
        background-color: unset;
        border-radius: 0;
        color: $secondary;
        font-family: $acumin-pro-font-family;
      }
    }
  }

  .profile-icon {
    @include max-break-point(tablet) {
      @include size(1.6em);
    }

    &.active-option {
      @extend .rounded-circle;

      background-color: $grey-200;
    }
  }
}

.select-dropdown {
  .select-button {
    border: 0.0825em solid $grey-400;
    border-radius: 0.25em;
    color: $grey-800;
    height: 3.6em;

    &.error {
      border: 0.0625em solid $danger;
      color: $danger;

      &::placeholder {
        color: $danger;
      }
    }

    .dropdown-icon {
      @extend .mt-1;

      color: $grey-500;
    }

    .placeholder-text {
      color: $grey-500;
    }
  }

  .profile-icon {
    @include size(1.25em);

    &.value {
      @include size(1.35em);
    }
  }

  .form-check-input {
    @include size(1em);
  }

  .dropdown-toggle {
    &::after {
      content: unset;
    }
  }

  .custom-dropdown-menu {
    @include custom-box-shadow($collapse-box-shadow);
    @include translate-X(-50%);

    bottom: -2em;
    height: fit-content;
    left: 50%;
    min-width: 100%;

    @include min-break-point(mobile-phone) {
      top: 2.5em;
    }

    &.icon-dropdown {
      height: 35em;
      /* stylelint-disable-next-line unit-allowed-list declaration-no-important */
      max-height: 40vh;

      @include min-break-point(tablet) {
        /* stylelint-disable-next-line unit-allowed-list declaration-no-important */
        max-height: 60vh;
      }
    }

    li {
      border-bottom: 0.0625em solid $grey-100;

      &:last-child {
        @extend .border-0;
      }
    }

    .dropdown-item {
      color: $grey-700;
      font-family: $acumin-pro-font-family;
      font-size: $p-font-size;
      height: 2.2em;

      &.active,
      &:focus,
      &:active {
        background-color: unset;
        border-radius: 0;
        color: $secondary;
      }

      &:hover {
        background-color: unset;
        border-radius: 0;
        color: $secondary;
        font-family: $acumin-pro-font-family;
      }
    }
  }
}
