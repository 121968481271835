.record-detail {
  background-color: $white;
  border-radius: 0.5em;

  .detail-header {
    color: $grey-800;
  }

  .detail-sub-title,
  .detail-value {
    color: $grey-700;
    font-family: $acumin-pro-font-family;
  }
}
