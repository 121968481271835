.home-tab-container {
  border-bottom: 0.0625em solid $grey-200;

  .home-tab {
    background-color: unset;
    border: 0;
    border-radius: 0;
    color: $grey-700;
    font-family: $acumin-pro-font-family;
    font-size: $p-font-size;
    min-width: 6em;

    &.active {
      border-bottom: 0.125em solid $primary;
      color: $primary;
    }
  }
}
