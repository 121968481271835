.call-priority {
  &-dropdown {
    background-color: unset;
    border: 0.0625em solid $grey-200;
    border-radius: 0.25em;
    color: $grey-700;
    font-size: $span-font-size;
    height: 1.9325em;
  }

  .custom-dropdown-menu {
    @extend .overflow-auto;
    max-height: 7.5em;

    min-width: unset;

    .dropdown-item {
      font-size: $span-font-size;
    }
  }
}
