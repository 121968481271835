.form-control,
.form-check-input {
  background-color: $white;
  border: 0.0625em solid $grey-400;
  border-radius: 0.25em;
  color: $grey-800;

  &.checked,
  &.radio {
    @include size(1.3em);

    cursor: pointer;
  }

  &:active {
    color: $grey-800;
  }

  &::placeholder {
    background-color: $white;
    color: $grey-500;
  }

  &:disabled {
    background-color: $grey-100;
    border: 0.0625em solid $grey-400;
    color: $grey-600;

    &::placeholder {
      background-color: $grey-100;
      color: $grey-600;
    }
  }

  &:focus {
    border-color: $primary;
    box-shadow: none;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @include transition(background-color 5000s ease-in-out 0s);

    -webkit-text-fill-color: $grey-800;
  }

  &.error {
    border: 0.0625em solid $danger;
    color: $danger;

    &::placeholder {
      color: $danger;
    }
  }

  .input-icon {
    @include translate-Y(-50%);

    color: $grey-500;
    cursor: pointer;
    font-size: $h5-font-size;
    right: 3%;

    &.active {
      color: $grey-800;
    }
  }

  .error-message {
    color: $danger;
    font-size: $span-font-size;
  }

  .tool-tip {
    &:hover,
    &:focus {
      .disable-icon {
        background-color: $warning;
        border-radius: 3em;
      }
    }
  }
}
