.incoming-call-index {
  .incoming-call-item {
    border-bottom: 0.0625em solid $grey-100;

    .device-name {
      color: $green-400;
      font-size: $p-font-size;
    }

    .busy-icon {
      color: $red-500;
      font-size: 1.1em;
    }

    .option-icon {
      font-size: 1.1em;
    }
  }

  .selected-call-routing {
    color: $grey-700;
    font-size: $span-font-size;
    height: 2.8em;
  }

  .incoming-call-menu {
    li {
      @extend .py-1;

      /* stylelint-disable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
      .incoming-call-menu-item {
        font-size: $span-font-size;
      }

      &.active,
      &:hover {
        background-color: $grey-100;
      }

      /* stylelint-enable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
    }
  }

  .incoming-call-menu-item {
    &.hover,
    &.active {
      color: $grey-700;
    }
  }

  /* stylelint-disable  selector-class-pattern, declaration-no-important */
  .css-xb97g8 {
    @extend .rounded-circle, .align-self-center, .mx-2;
    @include size(1.125em);

    background-color: $green-300;

    &:hover {
      background-color: $red-400;
    }

    svg {
      color: $white;
      transform: scale(1.3);
    }
  }

  .css-12u5han-Input {
    input {
      height: 1.5em;
    }
  }

  /* stylelint-enable selector-class-pattern, declaration-no-important */

  .forward-to-button {
    font-family: $acumin-pro-font-family;
    font-size: $span-font-size;
    height: 2.5em;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .forward-to-value {
    background-color: unset;
    border: 0.0825em solid $green-300;
    border-radius: 0.5em;
    box-sizing: border-box;
    color: $grey-700;
    cursor: pointer;
    font-size: $span-font-size;
    height: 2.175em;
    width: fit-content;

    @include max-break-point(tablet) {
      margin-top: -0.4em;
    }

    .close-icon {
      color: $green-300;
      font-size: 1.1em;
    }

    &:hover,
    &:focus {
      border: 0.0625em solid $red-400;

      .close-icon {
        color: $red-400;
      }
    }
  }

  .after {
    &.default-view {
      margin-top: -0.4em;
    }

    &.value {
      margin-top: 0.3em;
    }

    &.forward-to-text-with-sip-error {
      margin-top: -1.4em;
    }

    &.forward-to-text-with-single-device {
      margin-top: -2em;
    }

    &.forward-to-text-with-multiple-device {
      margin-top: -1.2em;
    }

    &.forward-to-text {
      margin-top: -2.1em;
    }

    &.after-ringing-error {
      margin-top: -0.4em;
    }

    &.after-ringing-error-with-device {
      margin-top: -1em;
    }

    &.after-ringing-error-with-multiple-device {
      margin-top: -0.2em;
    }
  }
}
