.custom-tooltip {
  cursor: pointer;
  outline: none;

  .tooltip-text {
    @extend .invisible;

    background-color: $black;
    border-radius: 0.5em;
    color: $white;
    font-size: $span-font-size;
    left: 1%;
    line-height: $h5-line-height;
    top: 100%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;

    &::after {
      border-color: transparent transparent $grey-900 transparent;
      border-style: solid;
      border-width: 0.3125em;
      bottom: 100%;
      content: "";
      left: 6%;
      margin-left: -0.3125em;
      position: absolute;
    }
  }

  &:hover {
    .tooltip-text {
      @extend .visible;
    }
  }
}

.tool-tip {
  border: unset;
  cursor: pointer;
  outline: unset;

  .tooltip-text {
    @include custom-box-shadow($collapse-box-shadow);

    background-color: $white;
    border-radius: 0.3125em;
    bottom: 100%;
    color: $grey-700;
    left: 50%;
    min-width: 17.0625em;
    transform: translateX(-50%);

    visibility: hidden;
    z-index: 1;

    &::after {
      @extend .position-absolute;

      border-color: $white transparent transparent transparent;
      border-style: solid;
      border-width: 0.5125em;

      content: "";
      left: 50%;
      margin-left: -0.3125em;
      top: 100%;
    }
  }

  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}
