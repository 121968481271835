.time-stamp-modal {
  border-radius: 0;
  height: 100%;
  right: 0;
  width: 90%;

  @include min-break-point(mobile-phone) {
    width: 80%;
  }

  @include min-break-point(tablet) {
    border-radius: 1em;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 46em;
  }

  @include min-break-point(larger-desktop) {
    width: 50.87em;
  }

  &-header {
    border-bottom: 0.0625em solid $grey-200;

    h5 {
      color: rgba($grey-800, 0.8);
      font-size: $modal-header-font-size;
    }

    .close-icon {
      color: $grey-700;
      cursor: pointer;
      font-size: 1.48em;
    }
  }

  &-container {
    border-bottom: 0.0625em solid $grey-200;
  }

  .schedule-description {
    color: $grey-700;
  }

  .add-time-frame-button {
    border-radius: 0.375em;
    height: 3em;
  }

  .time-stamp {
    /* stylelint-disable-next-line unit-allowed-list declaration-no-important */
    max-height: calc(100vh - 12em);
  }

  .configure-time-stamp {
    .time-stamp-container {
      border-top: 0.0625em solid $grey-200;

      &:first-child {
        border: 0;
      }
    }
  }

  .time-stamp-item {
    background-color: $grey-100;
    border: 0.0625em solid $grey-300;
    border-radius: 0.25em;
  }

  .dropdown-toggle {
    border: 0.0625em solid $grey-500;
    border-radius: 0.28em;

    .name {
      color: $grey-700;
    }

    .dropdown-icon {
      color: $grey-500;
    }
  }

  .choose-profile {
    color: rgba($color: $grey-800, $alpha: 0.8);
  }

  .delete-icon {
    color: $danger;
    cursor: pointer;
    font-size: 1.5em;

    &:hover {
      opacity: 0.8;
      transition: all 0.15s ease-in-out;
    }
  }

  .setup-time {
    @include size(2.2em, 6em);

    color: $grey-600;
  }

  .time-stamp-list {
    /* stylelint-disable-next-line unit-allowed-list declaration-no-important */
    max-height: calc(80vh - 4em);
  }

  .edit-icon {
    @include size(1.6em);
    cursor: pointer;

    svg {
      font-size: 0.8em;
    }
  }
}
