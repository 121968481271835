.automatic-call-profile-toggler {
  border-color: $grey-600;
  box-shadow: unset;
  height: 2.3125em;
  outline: unset;

  .toggler-text {
    color: $grey-600;
    font-size: $span-font-size;
  }

  .form-check-input {
    @include size(1.2125em, 2.6em);
    background-color: $grey-500;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
    cursor: pointer;
  }
}
