/* stylelint-disable selector-class-pattern,selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
.flatpickr-calendar {
  @extend .border-0;
  @extend .bg-white;
  @include custom-box-shadow($calender-box-shadow);

  min-width: 24em;

  &.arrowTop {
    &::after,
    &::before {
      border-bottom-color: $white;
    }
  }

  &:focus {
    background-color: $primary;
  }

  .flatpickr-innerContainer {
    margin-left: 1em;
  }

  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      @extend .mt-2;
      @extend .mx-2;
      @extend .d-flex;

      background-color: $grey;
      border-radius: 0.5em;
      top: 0.125em;

      svg {
        fill: $grey-600;
      }

      &:hover,
      &:focus {
        fill: $black;
      }

      &.flatpickr-disabled {
        svg {
          fill: $grey-400;
        }
      }
    }
  }

  .flatpickr-monthDropdown-months {
    @extend .fw-bold;
    @extend .py-2;
    @extend .px-2;

    background-color: $grey;
    border-radius: 0.5em 0.5em 0.8em 0.8em;
    color: $primary;
    font-size: $p-font-size;
    margin-top: -0.183em;
  }

  .flatpickr-days {
    @extend .border-0;
  }

  .flatpickr-months {
    @extend .pt-2;
    @extend .bg-white;
  }

  .flatpickr-month,
  .flatpickr-monthDropdown-month,
  .flatpickr-weekdays,
  .flatpickr-weekday {
    background-color: $white;
    color: $grey-800;
    font-size: $span-font-size;
  }

  .flatpickr-current-month {
    .flatpickr-monthDropdown-months {
      .flatpickr-monthDropdown-month {
        background-color: $white;
        border-radius: 0.5em;
      }

      option {
        &:hover {
          background-color: $primary;
        }
      }
    }
  }

  .flatpickr-weekdays {
    @extend .py-4;
  }

  .flatpickr-day {
    @extend .p-2;

    line-height: 1.75em;

    &:hover {
      background-color: $primary;
      color: $white;
    }

    &.inRange {
      @extend .fw-bold;

      background-color: $green-100;
      color: $primary;
    }

    &.selected,
    &.startRange,
    &.endRange {
      @extend .border-0;

      background-color: $primary;
    }
  }

  .arrowBottom {
    &::after {
      border-top-color: $white;
    }
  }
}

.flatpickr-current-month {
  @extend .border-0;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;

  input {
    &.cur-year {
      @extend .fw-bold;

      color: $grey-800;
      font-size: $p-font-size;
    }
  }

  .numInputWrapper {
    @extend .ms-2;

    cursor: pointer;
    margin-top: -0.2em;

    span {
      margin-right: -0.5em;
      margin-top: -0.0625em;
      opacity: 1;

      &.arrowUp {
        &::after {
          border-bottom-color: $grey-500;
        }
      }

      &.arrowDown {
        &::after {
          border-top-color: $grey-500;
        }
      }
    }
  }
}

/* stylelint-enable selector-class-pattern,selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
