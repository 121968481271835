.private-route-layout {
  background-color: $grey-100;

  .layout-spacing {
    padding: 0.5em 0.5em 0.8em 0.4em;

    @include min-break-point(mobile-phone) {
      padding: 0.5em 1.5em 0.8em 0.4em;
    }
  }
}
