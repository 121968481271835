.play-voicemail {
  @include size(2em);
  background-color: $primary;

  cursor: pointer;

  .play-border {
    border: 0.225em solid $primary;
    border-radius: 1.25em;
  }

  .play {
    border-bottom: 0.6em solid transparent;
    border-left: 0.8em solid $white;
    border-top: 0.6em solid transparent;
    box-sizing: border-box;
    cursor: pointer;
    left: 0.75em;
    top: 0.38em;
    transition: 100ms all ease;
  }

  &.is-loading {
    background-color: unset;

    .play {
      @extend .d-none;
    }

    .play-border {
      animation: spin 1.5s linear infinite;
      border-top: 0.25em solid $green-200;
    }
  }

  &.is-playing {
    background-color: unset;
    border-color: $orange;

    .play {
      @include size(1.0625em, 0.71em);
      border-bottom: unset;
      border-left: 0.25em solid $orange;
      border-right: 0.25em solid $orange;

      border-top: unset;
      box-sizing: border-box;
      left: 0.65em;
      top: 0.48em;
    }

    .play-border {
      border-color: $orange;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

iframe {
  display: none;
}
