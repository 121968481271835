.total-calls-in-queue {
  border-bottom: 0.0625em solid $grey-200;
  border-top: 0.0625em solid $grey-200;

  .total-calls-title {
    color: rgba($color: $grey-800, $alpha: 0.8);
    font-family: $acumin-pro-font-family;
    font-size: $h5-font-size;
  }

  .table-row {
    td {
      color: rgba($grey-800, 0.8);
      font-family: $acumin-pro-font-family;

      &:last-child {
        @extend .text-end, .pe-4;
      }
    }
  }

  .table-header {
    @extend .py-0;

    background-color: unset;
    height: 2em;

    th {
      @extend .fw-normal, .py-0;

      color: $grey-600;
      font-family: $neutrif-pro-font-family;

      font-size: $span-font-size;

      &:last-child {
        @extend .text-end, .pe-3;
      }
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0 0.4em;
    min-width: 24em;

    &-row {
      background-color: $grey-100;
      border-radius: 0.8em;
      box-shadow: unset;
    }
  }

  td:first-child {
    border-radius: 0.5em 0 0 0.5em;
  }

  td:last-child {
    border-radius: 0 0.5em 0.5em 0;
  }
}
