.call-profile-form {
  .save-button {
    min-width: 11.43em;
  }

  .delete-button {
    @include size(2.65em, 2.5em);

    background: $red-400;
    border-radius: 0.5em;

    &:hover,
    &:focus {
      background-color: $red-300;
      border-color: $red-300;
    }

    &:disabled {
      background: $red-200;
      border-color: $red-200;
    }
  }

  .profile-basic-form {
    @include max-break-point(tablet) {
      margin-left: 0;
    }
  }

  .call-profile-field {
    @include max-break-point(tablet) {
      padding: 0;
    }
  }

  .icon-button {
    color: $grey-700;
    font-size: 1.1em;
    height: 2.8em;
  }

  .tooltip-text {
    background-color: $black;
    border-radius: 0.625em;
    bottom: unset;
    color: $white;
    font-size: $font-12;
    left: 50%;
    min-width: 19.0625em;
    right: 0;
    top: 100%;
    transform: translateX(-50%);
    z-index: 1;

    &::after {
      border-color: $black transparent transparent transparent;
      bottom: 98%;
      content: "";
      left: 48%;
      margin-right: 0.2em;
      top: unset;
      transform: rotate(183deg) translateX(-50%);
    }
  }

  .forward-to-value {
    padding-left: 0.9em;
  }
}

.tooltip-text {
  &.active-call-profile-tooltip {
    border-radius: 0.625em;
    bottom: unset;
    left: unset;
    min-width: 17.0625em;
    right: 0;
    top: 100%;
    transform: unset;
    z-index: 1;

    &::after {
      border-color: $black transparent transparent transparent;
      bottom: 98%;
      content: "";
      left: unset;
      margin-left: 0;
      margin-right: 0.2em;
      right: 0;
      top: unset;
      transform: rotate(183deg);
    }
  }
}
