.password-notification {
  .completed-icon {
    @include size(4.5em);

    background-color: $green-100;

    .icon {
      color: $green-300;
      font-size: $h3-font-size;
    }
  }

  .notification-title {
    @include min-break-point(tablet) {
      white-space: nowrap;
    }
  }

  .description {
    color: $grey-700;
  }

  .title {
    color: $grey-800;
    font-family: $acumin-pro-font-family;
    font-size: $h5-font-size;
  }

  .message {
    color: $grey-700;
    font-family: $acumin-pro-font-family;
    font-size: $h5-font-size;
  }
}
