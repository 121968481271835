.outgoing-call-form {
  .outgoing-call-item {
    border-bottom: 0.0625em solid $grey-100;

    .device-name {
      color: $green-400;
      font-size: $p-font-size;
    }
  }

  .selected-call-routing {
    color: $grey-700;
    font-size: $span-font-size;
    height: 2.5em;
  }

  .outgoing-call-menu {
    li {
      @extend .py-1;

      /* stylelint-disable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
      .outgoing-call-menu-item {
        font-size: $span-font-size;
      }

      &.active,
      &:hover {
        background-color: $grey-100;
      }

      /* stylelint-enable selector-max-compound-selectors,max-nesting-depth, declaration-no-important */
    }
  }

  .outgoing-call-menu-item {
    &.hover,
    &.active {
      color: $grey-700;
    }
  }

  .error-message {
    margin-bottom: -0.775em;
  }
}
