.email-notification {
  .email-icon {
    @include size(4.5em);

    background-color: $green-100;

    .icon {
      color: $green-300;
      font-size: $h3-font-size;
    }
  }

  .description {
    color: $grey-700;
  }
}
