.profile-header {
  .edit-profile {
    border-radius: 0.5em;
    font-size: $span-font-size;
    height: 3.2em;
  }

  .profile-image {
    @include size(8.125em);

    background-color: $green-100;

    &.uploaded {
      background-color: $grey-200;
    }

    img {
      @include size(90%);

      object-fit: cover;
    }
  }

  .profile-replacement-text {
    font-size: $h3-font-size;
  }

  .overlay-camera {
    background-color: $primary-overlay;
    border-radius: 0;
    cursor: pointer;
  }

  .camera-icon {
    color: $white;
    font-size: 1.8em;
  }

  .button-replacement {
    pointer-events: unset;
    width: 10em;

    &.skeleton {
      width: 8em;
    }
  }
}

.profile-image-dropdown {
  bottom: 0.5em;
  right: -0.25em;

  .dot-icon {
    color: $grey-500;
    font-size: 1.5em;
  }

  .edit-icon {
    color: $grey-500;
    font-size: 0.95em;
  }

  .delete-icon {
    color: $grey-500;
    font-size: 0.95em;
    margin-right: 0.7em;
  }

  .dropdown-toggle {
    @include size(2.5625em);

    border: 0.0625em solid $grey-200;

    border-radius: 0.5em;

    &::after {
      @extend .d-none;
    }
  }

  .dropdown-menu {
    @include custom-box-shadow($collapse-box-shadow);
  }

  .dropdown-item {
    color: $grey-700;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $grey-200;
      border-radius: 0.5625em;
    }
  }
}
